/* LocalProLanding.css */

/* Base styles */
.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    overflow-x: hidden;
  }
  
  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  
  .gradientOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.17) 47%,
      rgba(0, 0, 0, 1) 71%,
      rgba(0, 0, 0, 1) 100%
    );
    pointer-events: none;
  }
  
  .imageSection {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .mobileMockup {
    top: 198px;
    left: 192px;
    width: 328px;
    height: auto;
  }
  
  .textSection {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    margin: auto;
  }
  
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 20px;
    font-weight: 500;
    color: rgb(55 53 53);
    margin-bottom: 10px;
  }
  
  .stepsList {
    font-size: 20px;
    color: #666;
    line-height: 1.8;
    list-style-type: none;
    padding: 0;
  }
  
  .storeButtons {
    display: flex;
    margin-top: 20px;
  }
  
  .storeIcon {
    height: 50px;
    cursor: pointer;
    margin-right: 30px;
    border-radius: 5px;
  }
  
  /* How It Works Section */
  .howItWorksContainer {
    text-align: center;
    background-color: #fff;
  }
  
  .howItWorksTitle {
    font-size: 30px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  
  .howItWorksSubtitle {
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-bottom: 30px;
  }
  
  .stepsContainer {
    display: grid;
    gap: 20px;
    justify-content: center;
  }
  
  .stepCard {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .stepIcon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  
  .stepTitle {
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .stepDescription {
    color: rgb(0 0 0);
  }
  
  /* Popular Services Section */
  .popularServicesContainer {
    text-align: center;
    padding: 50px 20px;
    background-color: #f4f4f4;
  }
  
  .sectionTitle {
    font-size: 42px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .subTitle {
    font-size: 28px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .serviceCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
    padding: 10px 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  .serviceCardsContainer::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  
  .serviceCard {
    width: 250px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    flex-shrink: 0;
    transition: transform 0.3s ease;
  }
  
  .imageWrapper {
    position: relative;
    width: 100%;
  }
  
  .serviceImage {
    width: 100%;
    height: 140px;
    object-fit: cover;
  }
  
  .serviceTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 5px;
    text-align: center;
  }
  
  .serviceInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    padding: 12px;
  }
  
  .serviceDetails {
    font-size: 12px;
    color: rgb(0, 0, 0);
    text-align: center;
  }
  
  /* Stats Section */
  .statsContainer {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
  }
  
  .statCard {
    text-align: center;
  }
  
  .statNumber {
    font-weight: 500;
    color: #000;
  }
  
  .statLabel {
    color: #666;
  }
  
  /* About Section */
  .aboutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(244, 244, 244);
  }
  
  .aboutContent {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    gap: 40px;
  }
  
  .aboutText {
    flex: 1;
  }
  
  .aboutTitle {
    font-size: 36px;
    font-weight: 500;
    color: rgb(0 0 0);
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .aboutDescription {
    font-size: 20px;
    color: rgb(0 0 0);
    padding: 20px;
  }
  
  .aboutImage {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .image {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  .storeIcon-mobile {
    display: none;
  }
  
  /* Small devices (phones, up to 576px) */
  @media (max-width: 576px) {
    /* Main container */
    .container {
      padding: 10px 0;
      overflow: visible;
    }
  
    .contentWrapper {
      flex-direction: column;
      padding: 0;
    }
  
    .gradientOverlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 1) 100%);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .imageSection {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: -100px; /* Pull the text section up */
      position: relative;
      z-index: 1;
    }
    .title {
      color: white;
      margin-right: 18px;
    }
    .textSection {
      width: 100%;
      padding: 30px 20px 40px;
      margin: 0;
      position: relative;
      z-index: 2;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 1) 100%);
      border-radius: 0;
      box-sizing: border-box;
      text-align: center;
    }
    .stepsList {
      color: white;
    }
    .mobileMockup {
      width: 70%;
      max-width: 300px;
      height: auto;
    }
  
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 15px;
      text-align: center;
    }
  
    .subtitle {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .stepsList {
      font-size: 14px;
      color: #ffffff;
      line-height: 1.8;
      text-align: left;
      margin: 0 auto;
      margin-bottom: 20px;
      max-width: 280px;
      padding-left: 10px;
    }
  
    .stepsList li {
      padding-left: 5px;
      margin-bottom: 8px;
    }
    
    .storeButtons {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-top: 20px;
    }
    .storeIcon-desktop {
      display: none;
    }
  
    .storeIcon-mobile {
      display: block;
      height: 40px;
    }
    
    .storeIcon {
      height: 40px;
      margin-right: 0;
    }
    
  
    /* How It Works Section */
    .howItWorksContainer {
      padding: 30px 15px;
    }
  
    .howItWorksTitle {
      font-size: 24px;
    }
  
    .howItWorksSubtitle {
      font-size: 14px;
      padding: 0 10px;
    }
  
    .stepsContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .stepCard {
      padding: 5px;
    }
  
    .stepTitle {
      font-size: 14px;
    }
  
    .stepDescription {
      font-size: 12px;
      padding: 0 5px;
    }
  
    /* Popular Services Section */
    .sectionTitle {
      font-size: 24px;
    }
  
    .subTitle {
      font-size: 18px;
    }
  
    .serviceCard {
      width: 180px;
    }
  
    /* Stats Container */
    .statsContainer {
      padding: 30px 10px;
    }
  
    .statCard {
      width: 50%;
      margin-bottom: 20px;
    }
  
    .statNumber {
      font-size: 18px;
    }
  
    .statLabel {
      font-size: 12px;
    }
  
    /* About Section */
    .aboutContainer {
      padding: 30px 15px;
    }
  
    .aboutContent {
      flex-direction: column;
    }
  
    .aboutTitle {
      font-size: 24px;
      text-align: center;
      padding-left: 0;
    }
  
    .aboutDescription {
      font-size: 16px;
      text-align: center;
    }
  }
  
  /* Medium devices (tablets, 577px to 768px) */
  @media (min-width: 577px) and (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
      padding: 20px;
    }
  
    .gradientOverlay {
      background: transparent
        linear-gradient(
          180deg,
          #ffffff00 0%,
          #0000002b 47%,
          #000000 71%,
          #000000 100%
        )
        0% 0% no-repeat padding-box;
    }
  
    .mobileMockup {
      width: 280px;
      height: auto;
    }
  
    .title {
      font-size: 28px;
      text-align: center;
    }
  
    .subtitle {
      text-align: center;
    }
  
    .stepsList {
      text-align: center;
    }
  
    .storeButtons {
      justify-content: center;
    }
  
    .stepsContainer {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .howItWorksContainer {
      padding: 40px 30px;
    }
  
    .howItWorksSubtitle {
      padding: 0 20px;
    }
  
    .stepCard {
      padding: 10px;
    }
  
    .stepTitle {
      font-size: 16px;
    }
  
    .serviceCard {
      width: 200px;
    }
  
    .statsContainer {
      padding: 50px 20px;
    }
  
    .statNumber {
      font-size: 36px;
    }
  
    .statLabel {
      font-size: 13px;
    }
  
    .aboutContent {
      flex-direction: column;
    }
  
    .aboutTitle {
      text-align: center;
      padding-left: 0;
    }
  
    .aboutDescription {
      text-align: center;
    }
  }
  
  /* Large devices (desktops, 769px to 992px) */
  @media (min-width: 769px) and (max-width: 992px) {
    .contentWrapper {
      padding: 40px 20px;
    }
    .gradientOverlay {
      background: transparent
        linear-gradient(
          180deg,
          #ffffff00 0%,
          #98989800 23%,
          #68686800 53%,
          #46464696 83%,
          #000000 100%
        )
        0% 0% no-repeat padding-box;
    }
    .howItWorksContainer {
      padding: 60px 40px;
    }
  
    .howItWorksSubtitle {
      padding: 0 100px;
    }
  
    .mobileMockup {
      width: 300px;
      height: auto;
    }
  
    .serviceCard {
      width: 220px;
    }
  
    .statsContainer {
      padding: 70px 40px;
    }
  
    .stepCard {
      padding: 15px;
    }
  }
  
  /* Extra large devices (large desktops, 993px and up) */
  @media (min-width: 993px) {
    .contentWrapper {
      flex-direction: row;
      padding: 70px 20px;
    }
    .gradientOverlay {
      background: transparent
        linear-gradient(
          180deg,
          #ffffff00 0%,
          #98989800 23%,
          #68686800 53%,
          #46464696 83%,
          #000000 100%
        )
        0% 0% no-repeat padding-box;
    }
    .howItWorksContainer {
      padding: 80px 180px 100px;
    }
  
    .howItWorksSubtitle {
      padding: 0 265px;
    }
  
    .stepsContainer {
      grid-template-columns: repeat(4, 1fr);
    }
  
    .stepCard {
      padding: 20px;
    }
  
    .stepTitle {
      font-size: 18px;
    }
  
    .stepDescription {
      font-size: 14px;
      padding: 0 20px;
    }
  
    .statsContainer {
      padding: 100px 110px;
    }
  
    .statNumber {
      font-size: 50px;
    }
  
    .aboutContainer {
      padding: 60px 0px 20px;
    }
  
    .aboutContent {
      flex-direction: row;
    }
  
    .aboutText {
      text-align: left;
    }
  }
  
  /* Animation CSS */
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
  }
  
  @keyframes highlight-pulse {
    0% {
      box-shadow: 0 0 0 rgba(255, 138, 0, 0);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 138, 0, 0.3);
    }
    100% {
      box-shadow: 0 0 0 rgba(255, 138, 0, 0);
    }
  }
  
  .active-section {
    animation: highlight-pulse 1.5s ease-in-out;
  }
  
  .section-animation {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0);
  }
  