* {
  font-family: "Poppins", sans-serif !important;

  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  /* float: left; */
}

#root {
  width: 100%;
  position: relative;
}

@media (max-width: 800px) {
  #root {
    width: 100%;
    overflow-x: hidden;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your CSS file */

/* Section animations */
.section-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Highlight animation for active section */
@keyframes highlight-pulse {
  0% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
  50% { box-shadow: 0 0 20px rgba(255, 138, 0, 0.3); }
  100% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
}

.active-section {
  animation: highlight-pulse 1.5s ease-in-out;
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Add a scroll padding to account for fixed header */
html {
  scroll-padding-top: 70px; /* Adjust this value to match your header height */
}

/* Section animation styles */

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
  /* Adjust the scroll padding based on your header height */
  scroll-padding-top: 70px;
}

/* Animation for section highlight when navigated to */
@keyframes highlight-pulse {
  0% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
  50% { box-shadow: 0 0 20px rgba(255, 138, 0, 0.3); }
  100% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
}

.active-section {
  animation: highlight-pulse 1.5s ease-in-out;
}

/* Initial state and animation for sections */
.section-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}